.sidebarCover{
    width: 100%;
}
.navCover{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    color: #3E4B5B;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .81rem;
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.sideProfileCover{
    position: relative;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    box-sizing: border-box;
    /* padding-left: 0.7rem; */
}
.sideProfileCover img{
    width: 35px;
    height: auto;
    border-radius: 50%;
    border: 1px solid #262626;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
}
.logged_user_info{
    padding-left: 15px;
    text-align: left;
    box-sizing: border-box;
}
.logged_user_name{
    box-sizing: border-box;
}
.logged_user_role{
    display: block;
    font-size: .63rem;
    text-transform: uppercase;
    color: rgba(0,0,0,0.4);
    letter-spacing: 1px;
}
.logged_user_toggler{
    padding: 3px;
    font-size: 14px;
    position: absolute;
    top: 50%;
    right: 15px;
    box-sizing: border-box;
    transform: translateY(-50%);
    background-color: rgba(0,0,0,0.08);
    border-radius: 6px;
}
.status_pill_green{
    background-color: #71c21a;
    width: 12px;
    height: 12px;
    border-radius: 30px;
    display: inline-block;
    vertical-align: middle;
}
.ulLinkCover{
    padding: 1rem 1.5rem;
    margin-bottom: 0px;
    box-sizing: border-box;
}
.subMenu{
    text-transform: uppercase;
    font-size: .72rem;
    letter-spacing: 1px;
    padding-top: 10px;
    padding-bottom: 0px;
    color: #448eef;
    /* color: #24262d; */
    padding: 7px;
    padding-left: 5px;
    padding-top: 0px;
}
.mainSideLink{
    border-bottom: none;
    position: relative;
    font-size: .9rem;
    color: #3E4B5B;
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
}
.mainSideLink:hover .svgMainCover{
    margin-left: 7px;
}
.mainSideLink:hover .span{
    margin-left: -3px;
}
.svgMainCover{
    padding: 7px 10px 7px 0px;
    width: 30px;
    box-sizing: border-box;
    transition: all 0.2s ease;
}
.svgMainCover svg{
    width: 20px;
    color: #24262d;
    fill: #24262d;
}
.span{
    transition: all 0.2s ease;
    display: block;
    flex: 1;
    margin-top: -2px;
}

































































