.layoutCover {
  width: 100%;
}
.navBodyCover {
  width: 100%;
  display: flex;
}
.bodyCover {
  width: 100%;
}
.sideCover {
  width: 326px;
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.desktopCover{
    visibility: visible;
}
.mobileCover{
    display: none;
}



@media (max-width: 1022px) {
  .sideCover {
    height: auto;
    background-color: #fff;
    width: 80px;
    box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
    position: relative;
  }
  .desktopCover{
      display: none;
    visibility: hidden;
}
.mobileCover{
    display: block;
}
}
