.bodyCover {
  width: 100%;
}
.navCover {
  width: 100%;
  height: 57px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navLeft {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  padding: 7px 0px;
  width: max-content;
  height: 100%;
}
/* 
.logged_user_i {
    background-color: red;
    height: 300px;
}
.avatar_w {
    width: 100%;
} */
.avatar_w img {
    margin-right: 30px;
    border-radius: 50%;
    cursor: pointer;
    width: 35px !important;
    width: 100%;
}
